import React from "react"
import "./App.css"
import Starter from "./sections/Starter"
import OurTeam from "./sections/OurTeam"
import Services from "./sections/Services"
import ScrollToTop from "./Components/ScrollToTop"

import { motion } from "framer-motion"
import Footer from "./sections/Footer"
import ContactPage from "./sections/ContactPage"
import Banner from "./sections/Banner"
import About from "./sections/About"

function App() {
  return (
    <motion.div initial="hidden" animate="show" className="App">
      <ScrollToTop />

      <Starter />
      <Services />
      <Banner />
      <About />
      <OurTeam />
      <ContactPage />
      <Footer />
    </motion.div>
  )
}

export default App
