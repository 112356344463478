import React from "react"
import "../styles/components/BrandName.scss"
import logo from "../assets/pl.svg"
function BrandName() {
  return (
    <div className="brandLogoName">
      <img src={logo} />
    </div>
  )
}

export default BrandName
