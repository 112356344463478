import React from "react"
import Title from "../Components/Title"
import Button from "../Components/Button"
import princeCard from "../assets/princeCard.png"
import vikasCard from "../assets/vikasCard2.png"
import rupaCard from "../assets/rupaCard.png"
import "../styles/sections/OurTeam.scss"
import TeamMembers from "../Components/TeamMembers"
import { useScroll } from "../Components/useScroll"
import { motion } from "framer-motion"
import { textAnimation, cardAnimation } from "../utils/Animations"
function OurTeam() {
  const [element, controls] = useScroll()
  return (
    <div>
      <div className="OurTeam-container" id="team" ref={element}>
        <div className="container">
          <motion.div
            className="details-container"
            variants={textAnimation}
            animate={controls}
            transition={{ duration: 1 }}
          >
            <Title title="Meet Our Team" color="blue" lineCenter={true} />
            <p>Our experts at your service.</p>
          </motion.div>
          <motion.div className="teamMembers-cards">
            <TeamMembers
              image={rupaCard}
              title="Manager"
              subTitle="Rupa Gupta"
              link="https://www.linkedin.com/in/vikas-jadhav-ab2ba5190/"
              variants={cardAnimation}
              animate={controls}
            />
            <TeamMembers
              image={vikasCard}
              title="owner"
              subTitle="Vikas Jadhav"
              link="https://www.linkedin.com/in/vikas-jadhav-ab2ba5190/"
              variants={cardAnimation}
              animate={controls}
            />

            <TeamMembers
              image={princeCard}
              title="Manager"
              subTitle="Prince Singh"
              link="https://www.linkedin.com/in/vikas-jadhav-ab2ba5190/"
              variants={cardAnimation}
              animate={controls}
            />
          </motion.div>
        </div>

        <div class="custom-shape-divider-bottom-1629022903">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default OurTeam
