import React from "react"

function Info({ logo, title, subTitle }) {
  return (
    <div class="info-content">
      <div className="logo">{logo}</div>
      <div className="title-content">
        <h2>{title}</h2>
        <h4>{subTitle}</h4>
      </div>
    </div>
  )
}

export default Info
