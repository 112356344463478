import React from "react"

import "../styles/sections/CompanyInfo.scss"
import Title from "../Components/Title"
import Info from "../Components/Info"
import { FiPhone } from "react-icons/fi"
import { GoLocation } from "react-icons/go"
import { FiMail } from "react-icons/fi"
function CompanyInfo() {
  return (
    <div>
      <div className="CompanyInfo-container">
        <div className="container">
          <div className="details-container">
            <Title title="Here to find us.." color="blue" />
          </div>
          <div className="info-cards">
            <Info
              logo={<FiMail />}
              title="Email"
              subTitle="support@dhanrajfinserve.in"
            />
            <Info logo={<FiPhone />} title="Phone" subTitle="+91 9223456781" />
            <Info
              logo={<GoLocation />}
              title="Location"
              subTitle="Office No 5, 1st Floor, Gaondevi Shopping Center, Dombivli East, Thane - 421203, Nr Old Model College Bus Stop, Midc Milap Nagar "
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyInfo
