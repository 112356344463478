import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import img1 from "../assets/tgirl1.jpg"
import img2 from "../assets/tboy1.jpg"
import img3 from "../assets/tgirl2.jpg"
import "../styles/sections/Banner.scss"
import Title from "../Components/Title"
export default function Banner() {
  return (
    <div class="carousel-wrapper" id="testimonials">
      <Title title="Testimonials" color="blue" lineCenter={true} />
      <Carousel
        infiniteLoop
        useKeyboardArrows
        autoPlay
        showThumbs={false}
        swipeable={true}
        interval={4000}
        showArrows={true}
        showIndicators={true}
        showStatus={true}
      >
        <div className="image">
          <img src={img1} />
        </div>
        <div className="image">
          <img src={img2} />
        </div>
        <div className="image">
          <img src={img3} />
        </div>
      </Carousel>
    </div>
  )
}
