import { motion } from "framer-motion"
import React from "react"
import "../styles/components/Card.scss"
import { cardAnimation } from "../utils/Animations"
function Card({ cardlogo, title, animateCustom }) {
  return (
    <motion.div
      className="card"
      variants={cardAnimation}
      animate={animateCustom}
      transition={{ stiffness: 5000 }}
    >
      <div className="card-logo">
        {" "}
        <img src={cardlogo} alt="services" />
      </div>
      <div className="card-title">
        <h4>{title}</h4>
      </div>
    </motion.div>
  )
}

export default Card
