import React from "react"
import { useScroll } from "../Components/useScroll"
import { MdEmail } from "react-icons/md"
import { ImLocation } from "react-icons/im"
import { AiFillInstagram, AiFillLinkedin } from "react-icons/ai"
import { motion } from "framer-motion"
import "../styles/sections/Footer.scss"
import { fromUp, fromDown } from "../utils/Animations"

function Footer() {
  const [element, controls] = useScroll()

  return (
    <div className="footer-container" ref={element}>
      <div className="container">
        <div className="main-container">
          <motion.div
            className="quick-links"
            variants={fromDown}
            animate={controls}
            transition={{ duration: 0.5 }}
          >
            <h3>Quick Links</h3>
            <ul>
              <li>
                <a href="#home">Home</a>
              </li>
              <li>
                <a href="#services">Services</a>
              </li>

              <li>
                <a href="#testimonials">Testimonials</a>
              </li>
              <li>
                <a href="#about">About Us</a>
              </li>
              <li>
                <a href="#team">Our Team</a>
              </li>
              <li>
                <a href="#contact">Contact Us</a>
              </li>
              <li>
                <a href="#">Terms & Conditions</a>
              </li>
              <li>
                <a href="#">Privacy Policy</a>
              </li>
            </ul>
          </motion.div>
          <motion.div
            className="industries"
            variants={fromUp}
            animate={controls}
            transition={{ duration: 0.5 }}
          >
            <h3>Loans</h3>
            <ul>
              <li>
                <a href="#">Personal Loan</a>
              </li>
              <li>
                <a href="#">Business Loan</a>
              </li>
              <li>
                <a href="#">Home Loan</a>
              </li>
              <li>
                <a href="#">Mortgage Loan</a>
              </li>
            </ul>
          </motion.div>
          <motion.div
            className="touch"
            variants={fromDown}
            animate={controls}
            transition={{ duration: 0.5 }}
          >
            <h3>Get in Touch</h3>
            <div className="touch-section">
              <div className="icon">
                <MdEmail />
              </div>
              <div className="detail">
                <div className="detail-name">Email</div>
                <div className="detail-content">
                  <a href="#">support@dhanrajfinserve.in</a>
                </div>
              </div>
            </div>

            <div className="touch-section">
              <div className="icon">
                <ImLocation />
              </div>
              <div className="detail">
                <div className="detail-name">Location</div>
                <div className="detail-content">
                  <a href="#">
                    Office No 5, 1st Floor, Gaondevi Shopping Center, Dombivli
                    East, Thane - 421203, Nr Old Model College Bus Stop, Midc
                    Milap Nagar
                  </a>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
        <div className="copyright">
          <div className="copy">
            <p>Copyright c 2021. All Rights Reserved.</p>
          </div>
          <div className="icons">
            <div className="icon">
              <a href="https://www.instagram.com/dhanrajfinserve/?hl=en">
                <AiFillInstagram />
              </a>
            </div>
            <div className="icon">
              <a href="https://in.linkedin.com/in/vikas-jadhav-ab2ba5190">
                <AiFillLinkedin />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
