import React, { useState } from "react"
import { useForm } from "react-hook-form"

import classnames from "classnames"
import CompanyInfo from "./CompanyInfo"
import "../styles/sections/ContactPage.scss"
function ContactPage() {
  const [data, setData] = useState({
    fullName: "",
    email: "",
    phone: "",
  })

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const { fullName, email, phone } = data
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onSubmit", reValidateMode: "onChange" })

  const onSubmit = async (e) => {
    // e.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}?tabId=Contact_Sheet&api_key=${process.env.REACT_APP_API_KEY}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify([[fullName, email, phone]]),
        }
      )
      await response.json()
      setData({
        ...data,
        fullName: "",
        email: "",
        phone: "",
      })
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <div className="main-container" id="contact">
      <div className="info-container">
        <CompanyInfo />
      </div>
      <div className="form-container">
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <h1>Contact Us</h1>
          <div
            className={classnames("form-inputs", {
              "is-invalid": errors.fullName,
            })}
          >
            <label className="form-label">Name</label>
            <input
              value={fullName}
              className="form-input"
              type="text"
              placeholder="Enter your name"
              {...register("fullName", {
                required: "this is Required",
                minLength: {
                  value: 4,
                  message: "please enter minimum 4 characters",
                },
                maxLength: 30,
              })}
              name="fullName"
              onChange={handleChange}
            />
            {errors.fullName && (
              <div className="invalid-feedback">{errors.fullName.message}</div>
            )}
          </div>
          <div
            className={classnames("form-inputs", {
              "is-invalid": errors.email,
            })}
          >
            <label className="form-label">Email</label>
            <input
              value={email}
              className="form-input"
              type="text"
              placeholder="Enter your Email"
              {...register("email", {
                required: "this is Required",
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: "please enter a valid email address",
                },
                minLength: {
                  value: 4,
                  message: "please enter minimum 4 characters",
                },
                maxLength: 30,
              })}
              name="email"
              onChange={handleChange}
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.email.message}</div>
            )}
          </div>

          <div
            className={classnames("form-inputs", {
              "is-invalid": errors.phone,
            })}
          >
            <label className="form-label">Mobile Number</label>
            <input
              value={phone}
              className="form-input"
              type="tel"
              placeholder="Enter your phone"
              {...register("phone", {
                required: "this is Required",
                pattern: {
                  value: /^\d{10}$/,
                  message: "please enter a valid 10-digit mobile number",
                },
              })}
              name="phone"
              onChange={handleChange}
            />
            {errors.phone && (
              <div className="invalid-feedback">{errors.phone.message}</div>
            )}
          </div>

          <button className="form-input-btn" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}

export default ContactPage
