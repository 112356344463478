import React from "react"
import Title from "../Components/Title"
import "../styles/sections/Services.scss"
import Card from "../Components/Card"
import businessLoan from "../assets/businessLoan.svg"
import personalLoan from "../assets/personalLoan.svg"
import homeLoan from "../assets/homeLoan.svg"
import mortgageLoan from "../assets/mortgageLoan.svg"
import { motion } from "framer-motion"
import { useScroll } from "../Components/useScroll"
import { reveal } from "../utils/Animations"
function Services() {
  const [element, controls] = useScroll()
  return (
    <div className="services-container" id="services" ref={element}>
      <div className="container">
        <motion.div
          className="details"
          animate={controls}
          variants={reveal}
          transition={{ delay: 0.1, stiffness: 300 }}
        >
          <Title title="Services" color="blue" lineCenter={true} />
          <h3>Our Services includes the following loan facilities</h3>
          <div className="services-info">
            <ul>
              <li>Personal Loan</li>
              <li>Business Loan</li>
              <li>Home Loan</li>
              <li>Mortgage Loan</li>
              <li>Ready to recieve service request 24/7</li>
            </ul>
          </div>
        </motion.div>
        <div className="cards" ref={element}>
          <Card
            title="Personal Loan"
            cardlogo={personalLoan}
            animateCustom={controls}
          />
          <Card
            title="Business Loan"
            cardlogo={businessLoan}
            animateCustom={controls}
          />

          <Card
            title="Home  Loan"
            cardlogo={homeLoan}
            animateCustom={controls}
          />
          <Card
            title="Mortgage Loan"
            cardlogo={mortgageLoan}
            animateCustom={controls}
          />
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1629022234">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  )
}

export default Services
