import React from "react"
import "../styles/components/TeamMembers.scss"
import OurTeam from "../sections/OurTeam"
import Button from "./Button"
import { FaLinkedin } from "react-icons/fa"
import { motion } from "framer-motion"
function TeamMembers({ image, title, subTitle, link, variants, animate }) {
  return (
    <motion.div class="our-team" variants={variants} animate={animate}>
      <div class="picture">
        <img src={image} alt="team members" />
      </div>
      <div class="team-content">
        <h3 class="title">{title}</h3>
        <h2 class="subTitle">{subTitle}</h2>
      </div>
      <ul class="social">
        <li>
          <a href={link}>
            <FaLinkedin />
          </a>
        </li>
      </ul>
    </motion.div>
  )
}

export default TeamMembers
