import React from "react"
import Title from "../Components/Title"
import "../styles/sections/About.scss"
import DhanrajImg from "../assets/dhanrajImg.svg"
function About() {
  return (
    <div className="about-container" id="about">
      <Title
        className="title"
        title="About Us"
        color="blue"
        lineCenter={true}
      />

      <div className="DhanrajImg-container">
        <h2>In Memory of</h2>
        <img className="Dhanraj-img" src={DhanrajImg} alt="" />
        <h2>Mr. Dhanraj Jadhav</h2>
      </div>

      <div className="about-details">
        <p>
          Dhanraj Finserve(DFS) is a Firm formed in May 2019 in Sweet Memories
          of Mr Dhanraj Jadhav – a symbol of Struggle, Strength, Dedication and
          Hardwork. DFS has 2 offices and 12 employees on Board working towards
          the company’s Vision.
        </p>
        <br />

        <p>
          Vision - “To be become Successful Corporate DSA PAN India in online
          and Offline Business with 25 Branches & 150 Employees by 2025”.
        </p>
        <br />

        <p>
          Mission – Serve Customers as Consultant PAN India and add Value by
          fulfilling their Financial Needs through Online and Offline modes.{" "}
        </p>
      </div>
    </div>
  )
}

export default About
